import React from 'react';

import instagram from '../../../assets/logo-instagram.svg';
import github from '../../../assets/logo-github.svg';
import linkedIn from '../../../assets/logo-linkedin.svg';
import twitter from '../../../assets/logo-twitter.svg';

import './SkillSocial.css';

const SkillSocial = () => {
  return (
    <div className='skillSocial container mx-auto  flex flex-col gap-6 h-full'>
      <div className='socialSection flex flex-row justify-evenly align-middle items-center bg-primary-bg min-w-full rounded-[20px] px-4 py-4'>
        <a
          href='https://twitter.com/Psy_k3y'
          target='_blank'
          rel='noreferrer'
          className='socialIcon container mx-auto max-w-fit flex align-middle items-center justify-center px-6 py-1 bg-[#1e1e1e] hover:bg-secondary-bg cursor-pointer transition-colors rounded-[10px]'
        >
          <img src={twitter} alt='twitter' width={40} />
        </a>
        <a
          href='https://www.linkedin.com/in/souradeep-bhattacharya-390490218/'
          target='_blank'
          rel='noreferrer'
          className='socialIcon container mx-auto max-w-fit flex align-middle items-center justify-center px-6 py-1 bg-[#1e1e1e] hover:bg-secondary-bg cursor-pointer transition-colors rounded-[10px]'
        >
          <img src={linkedIn} alt='linkedIn' width={40} />
        </a>
        <a
          href='https://github.com/ThePsyk3y'
          target='_blank'
          rel='noreferrer'
          className='socialIcon container mx-auto max-w-fit flex align-middle items-center justify-center px-6 py-1 bg-[#1e1e1e] hover:bg-secondary-bg cursor-pointer transition-colors rounded-[10px]'
        >
          <img src={github} alt='github' width={40} />
        </a>
        <a
          href='https://www.instagram.com/soura_not_deep/'
          target='_blank'
          rel='noreferrer'
          className='socialIcon container mx-auto max-w-fit flex align-middle items-center justify-center px-6 py-1 bg-[#1e1e1e] hover:bg-secondary-bg cursor-pointer transition-colors rounded-[10px]'
        >
          <img src={instagram} alt='instagram' width={40} />
        </a>
      </div>
      <div className='skillSection container mx-auto flex flex-col justify-between align-middle items-center gap-4 bg-primary-bg min-w-full rounded-[20px] px-4 py-4 text-white font-poppins text-xl h-full'>
        <div className='nameSubSection container mx-auto flex flex-row justify-between align-middle items-center font-light'>
          Name:
          <div className='name container mx-auto flex flex-row justify-end align-middle items-center font-semibold'>
            Souradeep Bhattacharya
          </div>
        </div>
        <div className='locSubSection container mx-auto flex flex-row justify-between align-middle items-center font-light'>
          Location:
          <div className='location container mx-auto flex flex-row justify-end align-middle font-bold'>
            Kolkata, India
          </div>
        </div>
        <div className='skillSubSection container mx-auto flex flex-row justify-between align-middle items-center'>
          Skills:
          <div className='skills container mx-auto flex flex-row justify-end align-middle items-center gap-3'>
            <div className='skill w-10'>
              <img
                src={
                  'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/react/react.png'
                }
                alt='React'
              />
            </div>
            <div className='skill w-10'>
              <img
                src={
                  'https://raw.githubusercontent.com/github/explore/42198dc9113595ddd22cc12771bb719c8cf08b67/topics/svelte/svelte.png'
                }
                alt='Svelte'
              />
            </div>
            <div className='skill w-10'>
              <img
                src={
                  'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/nodejs/nodejs.png'
                }
                alt='NodeJs'
              />
            </div>
            <div className='skill w-10'>
              <img
                src={
                  'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/javascript/javascript.png'
                }
                alt='JS'
              />
            </div>
            <div className='skill w-10'>
              <img
                src={
                  'https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/typescript/typescript.png'
                }
                alt='TS'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillSocial;
