import React from 'react';

import profile from '../../../assets/profile.png';
import NavSection from './NavSection/NavSection';

const PersonalBio = () => {
  return (
    <div className='personalBio container mx-auto flex flex-row gap-6 font-poppins text-[#f5f5f5]'>
      <div className='photoSection flex-1'>
        <img src={profile} alt='profilePhoto' />
      </div>
      <div className='bioSection container mx-auto flex flex-col gap-3 flex-1 '>
        <NavSection />
        <div className='aboutSection container mx-auto flex flex-col py-4 px-4 rounded-[18px] bg-primary-bg h-full gap-y-5'>
          <div className='aboutTitle font-bold text-2xl'>About</div>
          <div className='aboutTxt font-light text-primary-text'>
            I am Souradeep Bhattacharya, a proficient web developer and engineering student experienced in React,
            JavaScript, SvelteKit, Python, C++, and C. I specialize in efficient and dynamic web application development
            with a focus on writing clean, maintainable code. Committed to continuous learning, I stay up-to-date with
            the latest technologies and industry best practices.
          </div>
          <div className='seemoreBtn self-end cursor-pointer hover:text-secondary-bg'>See More</div>
        </div>
      </div>
    </div>
  );
};

export default PersonalBio;
