import React from 'react';
import MainContainer from '../components/MainContainer/MainContainer';

const home = () => {
  return (
    <div className='container mx-auto min-w-full min-h-full p-5'>
      <MainContainer />
    </div>
  );
};

export default home;
