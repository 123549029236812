import React from 'react';
import DevPortFolio from './DevPortfolio/DevPortFolio';
import SkillSocial from './SkillSocial/SkillSocial';
import HeroInfo from './HeroInfo/HeroInfo';
import PersonalBio from './PersonalBio/PersonalBio';

const MainContainer = () => {
  return (
    <div className='mainContainer mx-auto container w-full h-full flex flex-col gap-6 align-middle justify-center scale-110'>
      <div className='topContainer container mx-auto flex flex-row gap-6'>
        <div className='topLeftContainer container mx-auto'>
          <HeroInfo />
        </div>
        <div className='topRightContainer container mx-auto'>
          <PersonalBio />
        </div>
      </div>
      <div className='bottomContainer container mx-auto flex flex-row gap-6'>
        <div className='bottomLeftContainer container mx-auto basis-2/3'>
          <DevPortFolio />
        </div>
        <div className='bottomRightContainer container mx-auto basis-1/3'>
          <SkillSocial />
        </div>
      </div>
    </div>
  );
};

export default MainContainer;
