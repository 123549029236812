import React, { useState } from 'react';

const NavSection = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const genericHamburgerLine = `h-1 w-8 my-1 rounded-full bg-white transition ease transform duration-300`;

  const handleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <div className='navSection container mx-auto flex flex-row gap-4 justify-between w-full cursor-pointer'>
      <div className='pageTitle font-bold text-2xl py-4 px-4 rounded-[18px] bg-primary-bg w-full'>ThePsyk3y</div>
      <div
        className='flex flex-col py-2 px-6 rounded-[18px] justify-center items-center group test bg-primary-bg'
        onClick={handleNav}
      >
        <div className={`${genericHamburgerLine} ${isNavOpen ? 'h-1 rotate-45 translate-y-3 opacity-100' : ''}`} />
        <div className={`${genericHamburgerLine} ${isNavOpen ? 'h-1 opacity-0' : 'opacity-100 '}`} />
        <div className={`${genericHamburgerLine} ${isNavOpen ? 'h-1 -rotate-45 -translate-y-3 opacity-100 ' : ''}`} />
      </div>
    </div>
  );
};

export default NavSection;

//framer motion

//gsap libraries
