import React, { useState } from 'react';

import project1 from '../../../assets/project1.png';
import project2 from '../../../assets/project4.png';
import project3 from '../../../assets/project3.png';

import './DevPortFolio.css';

const DevPortFolio = () => {
  const [hoveredImage, setHoveredImage] = useState(null);

  const handleHover = (image) => {
    setHoveredImage(image);
  };

  const handleHoverEnd = () => {
    setHoveredImage(null);
  };

  return (
    <div className='devPortfolio container mx-auto px-6 py-6 flex flex-col gap-y-10 bg-primary-bg rounded-[20px] h-full'>
      <div className='devPortfolioTop flex flex-row justify-between'>
        <div className='devTitle text-primary-text font-bold font-poppins text-2xl'>Dev&nbsp;Portfolio</div>
        <div className='seeAllBtn text-primary-text font-light font-poppins text-2xl cursor-pointer hover:text-secondary-bg transition-colors'>
          See&nbsp;All
        </div>
      </div>
      <div className='devPortfolioBottom container mx-auto columns-3'>
        <div
          className='projectContainer conatiner mx-auto h-full rounded-[10px] relative'
          onMouseOver={() => handleHover(project1)}
          onMouseLeave={handleHoverEnd}
        >
          <img src={project1} alt='project1' className='cursor-pointer rounded-[20px]' />
          {hoveredImage === project1 && (
            <div className='readMoreOverlay cursor-pointer flex items-center justify-center bg-black bg-opacity-50  text-white font-poppins font-semibold text-xl drop-shadow-sm rounded-[20px] h-full w-full absolute top-0 left-0'>
              Read More
            </div>
          )}
        </div>
        <div
          className='projectContainer conatiner mx-auto h-full rounded-[10px] relative'
          onMouseOver={() => handleHover(project3)}
          onMouseLeave={handleHoverEnd}
        >
          <img src={project3} alt='project3' className='cursor-pointer rounded-[20px]' />
          {hoveredImage === project3 && (
            <div className='readMoreOverlay cursor-pointer flex items-center justify-center bg-black bg-opacity-50 text-white font-poppins font-semibold text-xl drop-shadow-sm rounded-[20px] h-full w-full absolute top-0 left-0 backdrop:blur-md'>
              Read More
            </div>
          )}
        </div>
        <div
          className='projectContainer conatiner mx-auto h-full rounded-[10px] relative'
          onMouseOver={() => handleHover(project2)}
          onMouseLeave={handleHoverEnd}
        >
          <img src={project2} alt='project2' className='cursor-pointer rounded-[20px]' />
          {hoveredImage === project2 && (
            <div className='readMoreOverlay cursor-pointer flex items-center justify-center bg-black bg-opacity-50 text-white font-poppins font-semibold text-xl drop-shadow-sm rounded-[20px] h-full w-full absolute top-0 left-0'>
              Read More
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DevPortFolio;
