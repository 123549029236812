import React from 'react';

const HeroInfo = () => {
  return (
    <div className='heroInfo container mx-auto flex flex-col justify-between h-full gap-y-8'>
      <div className='heroTop container flex flex-col justify-between gap-5 bg-primary-bg py-5 px-5 rounded-[20px] text-4xl h-full shadow-sp1'>
        <div className='TitleText text-primary-text font-biryani font-black'>
          Hey,
          <br /> I'm <span className='text-secondary-bg'>Souradeep</span>.
        </div>
        <div className='subTextCTA container flex flex-row justify-between gap-10'>
          <div className='SubTitleText text-primary-text font-poppins text-xl'>I am a web developer.</div>
          <div className='hireCTA self-end max-w-fit text-primary-text align-middle font-biryani font-bold text-xl py-2 px-4 rounded-[15px] bg-secondary-bg'>
            Hire Me 👨‍💻
          </div>
        </div>
      </div>
      <div className='HeroBottom container flex flex-row gap-x-10'>
        <div className='HeroBottomLeft container flex flex-col justify-center align-middle bg-secondary-bg shadow-sp1 h-full font-biryani text-primary-text rounded-[20px] py-8 px-8'>
          <div className='titleText container mx-auto text-center text-[60px]'>1+</div>
          <div className='subText container mx-auto text-center text-[30px]'>Years Experience</div>
        </div>
        <div className='HeroBottomRight container mx-auto flex flex-col justify-center align-middle bg-primary-text shadow-sp2 h-full font-biryani text-secondary-bg rounded-[20px] py-8 px-8'>
          <div className='titleText conatiner mx-auto text-center text-[60px]'>14+</div>
          <div className='subText container mx-auto text-center text-[30px]'>Projects Handled</div>
        </div>
      </div>
    </div>
  );
};

export default HeroInfo;
